.product {
  position: relative;

  a {
    text-decoration: none;
    img {
      width: 100%;
      height: auto;
    }
  }

  .img-wrapper {
    display: block;
    width: 100%;
    height: 240px;
    border: 1px solid #afafaf;
    border-bottom: 0;
    overflow: hidden;
    text-align: center;

    img {
      // Styles for img if needed later
    }
  }

  .info{
    width: 100%;
    background: #000;
    color: #fff;
    position: relative;
    padding: 40px 10px 20px 10px;
    text-align: center;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    transition-property: color, height;
    transition-duration: 0.3s, 0.4s;
    transition-timing-function: ease-out;
    height: 83px;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      transform: scaleY(0);
      transform-origin: 50%;
      transition: transform 0.3s ease-out;
    }

    &:not(.basket-item):after {
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      z-index: -1;
      content: '';
      border-style: solid;
      transition-duration: 0.3s;
      transition-property: transform;
      left: calc(50% - 11px);
      bottom: 0;
      border-width: 10px 10px 0 10px;
      border-color: black transparent transparent transparent;
    }
  }

  &:hover .info {
    height: 40px;

    &:before {
      transform: scaleY(0.7);
    }

    &:after {
      visibility: visible;
      transform: translateY(10px);
    }
  }

  .title {
    transition: transform 0.3s ease-out;

    a {
      color: inherit;
    }
  }

  &:hover .title {
    transform: translateY(-18px);
    font-weight: bold;
    color: #000;
  }

  .price {
    background: #e32d2c;
    position: absolute;
    font-size: 1.3em;
    padding: 4px 13px;
    top: -15px;
    right: 10px;

    &.sale {
      background: #00ba2f;
    }

    &.original {
      font-size: 0.95em;
      padding: 4px 6px;
      text-decoration: line-through;
      top: -43px;
    }
  }

  .actions-wrapper {
    display: flex;
    justify-content: space-around;
    visibility: hidden;
    vertical-align: middle;
    .add-btn {
      width: 50%;
      padding: 4px 0;
      text-align: center;
      color: #191919;
      font-size: 0.75em;
      font-weight: bold;
      cursor: pointer;
      img {
        margin-right: 2px;
        vertical-align: middle;
      }
    }

    .wishlist {
      border-right: 1px solid #afafaf;

      &:hover {
        color: #e32d2c;
      }
    }

    .basket:hover {
      color: #0a75b9;
    }
  }

  .product-spacer {
    height: 38px;
  }

  &:hover .actions-wrapper * {
    visibility: visible;
  }

  .note {
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px 8px;
    font-size: 0.9em;

    &.on-sale {
      background: #00ba2f;
      color: #fff;
    }

    &.no-stock {
      background: #191919;
      color: #fff;
    }
  }
}

/* Media query for 4 products per row */
@media screen and (max-width: 1400px) {
  .product_container {
    width: 24%; 
    &:not(:nth-child(4n)) {
      margin-right: 8px;
    }
    .product .title {
      font-size: 0.8em;
    }
  }
}

/* Media query for 3 products per row */
 @media screen and (max-width: 991px) {
   .product_container {
     width: 32%; 
     &:not(:nth-child(3n)) {
       margin-right: 8px;
    }
   }
}

/* Media query for 2 products per row */
@media screen and (max-width: 660px) {
  .product_container {
    width: 48%; 
    &:not(:nth-child(2n)) {
      margin-right: 8px;
    }
  }
}

/* Media query for 1 product per row */
@media screen and (max-width: 480px) {
  .flex_container {
    justify-content: center;
  }
  .product_container {
    margin-right: 0!important;
    width: 95%; 
    .product{
      margin-bottom: 12px;
    }
    
  }
}
